import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import Button from 'common/src/components/Button';
import Heading from 'common/src/components/Heading';
import Input from 'common/src/components/Input';
import Container from 'common/src/components/UI/ContainerTwo';
import { useAlert } from 'react-alert';
import Loader from 'common/src/components/Loader';
import { MetroSpinner } from 'react-spinners-kit';
import displayErrors from '../../../utils';
import { PayPalButton, PaypalOrder } from 'react-paypal-button-v2';
import queryString from 'query-string';

import BannerWrapper, { BannerContent, Subscribe } from './banner.style';
import axios from 'axios';

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function Banner(props) {
  const alert = useAlert();
  const [email, setEmail] = useState('');

  const preValidation = () => {
    return validateEmail(email);
  };

  useEffect(() => {
    if (window) {
      setEmail(queryString.parse(window.location.search).email);
    }
  }, []);

  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Heading as="h1" content="Daily ACT Pro. $4.99/month" />
          <span className="banner-caption">
            <b>What you'll get:</b>
            <ul style={{ 'padding-left': '25px', 'text-align': 'left' }}>
              <li style={{ 'list-style-type': 'disc' }}>
                Every morning, you'll get one real, actual ACT question recently
                asked in an exam.
              </li>
              <li style={{ 'list-style-type': 'disc' }}>
                <b>Includes the complete solution</b> written by perfect score &
                scholarship winning test tutor. Learn how to solve the problem
                with complete explanation and intuition.
              </li>
              <li style={{ 'list-style-type': 'disc' }}>
                <b>Cancel anytime.</b>
              </li>
            </ul>
          </span>
          <Subscribe>
            <Input
              inputType="email"
              placeholder="Enter Email Address"
              iconPosition="left"
              aria-label="email"
              value={email}
              onInput={(e) => setEmail(e.target.value)}
            />
          </Subscribe>
          <br />
          <PayPalButton
            shippingPreference="NO_SHIPPING"
            createSubscription={(data, actions) => {
              if (preValidation()) {
                return actions.subscription.create({
                  plan_id: process.env.GATSBY_PAYPAL_SUBSCRIPTION_ID,
                });
              }
              alert.show(
                <div style={{ textTransform: 'initial' }}>
                  Please provide a valid email!
                </div>,
                {
                  type: 'error',
                }
              );
            }}
            onApprove={(data, actions) => {
              return actions.subscription.get().then(function (details) {
                const subscriptionId = data.subscriptionID;
                navigate(
                  `/payment/?email=${encodeURIComponent(
                    email
                  )}&subscriptionId=${encodeURIComponent(subscriptionId)}`
                );
              });
            }}
            options={{
              clientId: process.env.GATSBY_PAYPAL_CLIENT_ID,
              vault: true,
            }}
          />
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
}

export default Banner;
